import { JSERVER_MICRO_SERVICE_IDS } from "server/model"

export const ABSOLUTE_URL_REGEX = new RegExp(/^https?:\/\//)
export const SAAS_MVT_URL_REGEX = `^.*\/${JSERVER_MICRO_SERVICE_IDS.MVT_CACHE}\/rest\/v1\/organizations\/.+\/projects\/.+\/mvt\/[0-9]+\/[0-9]+\/[0-9]+$`
export const SAAS_GEOJSON_URL_REGEX = `^.*\/${JSERVER_MICRO_SERVICE_IDS.DAS}\/rest\/v1\/organizations\/.+\/collections\/.+\/items.*$`
export const SAAS_MAPBOX_URL_REGEX = `^.*\/${JSERVER_MICRO_SERVICE_IDS.CONFIGURATION}\/rest\/v1\/organizations\/.+\/projects\/.+\/mapbox.*$`
export const SAAS_WMS_URL_REGEX = `^.*\/${JSERVER_MICRO_SERVICE_IDS.MAP_IMAGE}\/wms.*$`

export interface windowCoords {
  top: number
  bottom: number
  right: number
  left: number
}
