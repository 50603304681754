import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, TextField, Typography } from "@mui/material"
import { translate } from "app/language/service"
import { useAppSelector } from "app/store/hooks"
import { JRasterFileInfo } from "file/model"
import { useTags } from "organization/hooks"
import { projectionSVC } from "projection/service"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { JRasterDataSourceSubmitValues } from "spatialdatasource/model"
import { createDataSource, updateDataSource, updateDataSourceTags } from "spatialdatasource/utils"
import { StatusChip } from "ui/components/StatusChip"
import { STATUS_CHIP_LEVELS } from "ui/model"
import { useErrorHandling } from "app/hook"

interface JRasterDataSourceFormDialogProps {
  close: () => void
  fileInfos: JRasterFileInfo[]
  afterSubmit: () => void
}

interface JRasterDataSourceFormValues {
  name: string
  description: string
  tags: string[]
}

export const RasterDataSourceFormDialog = (props: JRasterDataSourceFormDialogProps) => {
  // If the SDS is being updated, we will try to set the state of this dialog according
  // to the existing values, as much as possible
  const sdsToUpdate = useAppSelector(state => state.sds.sdsToUpdate)

  const { hasError, errorMessage, handleError, resetError } = useErrorHandling(translate(sdsToUpdate !== null ? "sds.update.error" : "sds.create.error"))

  const existingTags = useTags()

  // This is just for display
  const rasterProj = React.useMemo(() => projectionSVC.getProjections().find(proj => proj.code === "EPSG:3857"), [])

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<JRasterDataSourceFormValues>({
    defaultValues: {
      name: sdsToUpdate !== null ? sdsToUpdate.name : props.fileInfos[0].filename.replace(/\.[^/.]+$/, ""), // Use the first selected file, remove file extension
      description: sdsToUpdate !== null ? sdsToUpdate.description : "",
      tags: sdsToUpdate !== null ? sdsToUpdate.tags.map(t => t.name) : []
    }
  })

  const onSubmit = async (values: JRasterDataSourceFormValues) => {
    if (hasError) {
      resetError()
    }

    const submitValues: JRasterDataSourceSubmitValues = {
      ...values,
      type: "FILE_RASTER",
      fileIds: props.fileInfos.map(value => value.id)
    }

    try {
      if (sdsToUpdate !== null) {
        await updateDataSource({ ...submitValues, id: sdsToUpdate.id })
        await updateDataSourceTags(sdsToUpdate, values.tags)
      } else {
        await createDataSource(submitValues)
      }
    } catch (error: any) {
      handleError(error)
      return
    }

    /** `afterSubmit` callback is defined in {@link DataSourcePage} */
    props.afterSubmit()
  }

  return (
    <Dialog maxWidth="md" fullWidth open>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <DialogTitle sx={{ m: 0, p: 2 }}>{`${translate("sds.dialog.title")} (Raster)`}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Name */}
            <Grid item xs={6}>
              <Controller
                control={control}
                name="name"
                rules={{ required: { value: true, message: translate("label.field.required") } }}
                render={({ field }) => <TextField {...field} required error={errors.name !== undefined} helperText={errors.name?.message} type="text" label={translate("label.name")} fullWidth />}
              />
            </Grid>

            {/* CRS */}
            <Grid item xs={6}>
              <TextField value={rasterProj.label} required fullWidth label={translate("label.crs")} disabled />
            </Grid>

            {/* Description */}
            <Grid item xs={6}>
              <Controller control={control} name="description" render={({ field }) => <TextField {...field} multiline type="text" label={translate("label.description")} fullWidth />} />
            </Grid>

            {/* Tags */}
            <Grid item xs={6}>
              <Controller
                control={control}
                name="tags"
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    options={existingTags.map(t => t.name)}
                    disableListWrap
                    onChange={(_event, tags) => field.onChange(tags)}
                    renderInput={params => <TextField {...params} label={translate("label.tags")} />}
                    multiple
                    freeSolo
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => <StatusChip label={option} level={STATUS_CHIP_LEVELS.NEUTRAL} {...getTagProps({ index })} />)
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "space-between" }}>
          {hasError ? (
            <Typography color="error" sx={{ marginLeft: "0.5em" }}>
              {errorMessage}
            </Typography>
          ) : (
            <div />
          )}
          <Stack direction="row" alignItems="center" spacing={1}>
            {isSubmitting && <CircularProgress size={20} />}
            <Button disabled={isSubmitting} variant="outlined" onClick={props.close}>
              {translate("button.cancel")}
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {translate(sdsToUpdate !== null ? "button.save" : "button.create")}
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  )
}
