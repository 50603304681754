import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, TextField, Typography } from "@mui/material"
import { translate } from "app/language/service"
import { useTags } from "organization/hooks"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { JWmsDataSourceSubmitValues } from "spatialdatasource/model"
import { createDataSource, isHttpsUrl } from "spatialdatasource/utils"
import { StatusChip } from "ui/components/StatusChip"
import { STATUS_CHIP_LEVELS } from "ui/model"
import { useErrorHandling } from "app/hook"

interface JWmsWmtsDataSourceFormDialogProps {
  close: () => void
  afterSubmit: () => void
}

interface JWmsWmtsDataSourceFormValues {
  name: string
  capabilitiesUrl: string
  description: string
  tags: string[]
}

export const WmsWmtsDataSourceFormDialog = (props: JWmsWmtsDataSourceFormDialogProps) => {
  const { hasError, errorMessage, handleError, resetError } = useErrorHandling(translate("sds.create.error"))

  const existingTags = useTags()

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<JWmsWmtsDataSourceFormValues>({
    defaultValues: {
      name: "",
      capabilitiesUrl: "",
      description: "",
      tags: []
    }
  })

  const onSubmit = async (values: JWmsWmtsDataSourceFormValues) => {
    if (hasError) {
      resetError()
    }

    const submitValues: JWmsDataSourceSubmitValues = {
      ...values,
      type: "WMS_WMTS"
    }

    await createDataSource(submitValues)
      .then(props.afterSubmit)
      .catch(error => {
        handleError(error)
      })
  }

  return (
    <Dialog maxWidth="md" fullWidth open>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <DialogTitle sx={{ m: 0, p: 2 }}>{`${translate("sds.dialog.title")} (WMS/WMTS)`}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Name */}
            <Grid item xs={6}>
              <Controller
                control={control}
                name="name"
                rules={{ required: { value: true, message: translate("label.field.required") } }}
                render={({ field }) => <TextField {...field} required error={errors.name !== undefined} helperText={errors.name?.message} type="text" label={translate("label.name")} fullWidth />}
              />
            </Grid>

            <Grid item xs={6} />

            {/* Capabilities URL */}
            <Grid item xs={12}>
              <Controller
                control={control}
                name="capabilitiesUrl"
                rules={{
                  required: { value: true, message: translate("label.field.required") },
                  validate: {
                    https: value => isHttpsUrl(value) || translate("sds.https.required")
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    error={errors.capabilitiesUrl !== undefined}
                    helperText={errors.capabilitiesUrl?.message}
                    type="text"
                    label={translate("sds.wms.wmts.get.capabilities.url")}
                    fullWidth
                  />
                )}
              />
            </Grid>

            {/* Description */}
            <Grid item xs={6}>
              <Controller control={control} name="description" render={({ field }) => <TextField {...field} multiline type="text" label={translate("label.description")} fullWidth />} />
            </Grid>

            {/* Tags */}
            <Grid item xs={6}>
              <Controller
                control={control}
                name="tags"
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    options={existingTags.map(t => t.name)}
                    disableListWrap
                    onChange={(_event, tags) => field.onChange(tags)}
                    renderInput={params => <TextField {...params} label={translate("label.tags")} />}
                    multiple
                    freeSolo
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => <StatusChip label={option} level={STATUS_CHIP_LEVELS.NEUTRAL} {...getTagProps({ index })} />)
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "space-between" }}>
          {hasError ? (
            <Typography color="error" sx={{ marginLeft: "0.5em" }}>
              {errorMessage}
            </Typography>
          ) : (
            <div />
          )}
          <Stack direction="row" alignItems="center" spacing={1}>
            {isSubmitting && <CircularProgress size={20} />}
            <Button disabled={isSubmitting} variant="outlined" onClick={props.close}>
              {translate("button.cancel")}
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {translate("button.create")}
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  )
}
