import { LinearProgress } from "@mui/material"
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro"
import { translate } from "app/language/service"
import React from "react"

export const PortalDataGrid = (props: DataGridProProps & { rowType: string }) => {
  const { componentsProps, ...remainingProps } = props

  return (
    <DataGridPro
      sx={{
        "width": "100%",
        "height": "100%",
        "& *": {
          userSelect: "none"
        }
      }}
      pagination
      rowsPerPageOptions={[50, 100]}
      disableSelectionOnClick
      disableColumnSelector
      localeText={{ noRowsLabel: translate(`${props.rowType}.grid.no.rows.label`) }}
      hideFooterSelectedRowCount
      sortingOrder={["asc", "desc"]}
      components={{
        LoadingOverlay: LinearProgress
      }}
      componentsProps={{
        pagination: {
          showFirstButton: true,
          showLastButton: true
        },
        ...componentsProps
      }}
      {...remainingProps}
    ></DataGridPro>
  )
}
