import { GridFilterModel, GridSortModel } from "@mui/x-data-grid"
import { JTag } from "organization/model"
import { DATA_SOURCE_STATUSES, DATA_SOURCE_TYPES, DETAILS_DIALOG_SECTION, DETAILS_DIALOG_SECTIONS_PERMISSIONS, JDataSource, JDataSourceSubmitValues } from "spatialdatasource/model"
import { sdsRPO } from "spatialdatasource/repository"
import { STATUS_CHIP_LEVELS } from "ui/model"
import { JDataGridPagedResponse } from "ui/tools/grid"
import { getUserOrganization } from "user/tools/common"

export function getDataSources(page: number, size: number, sortModel: GridSortModel, filterModel: GridFilterModel): Promise<JDataGridPagedResponse<JDataSource>> {
  return sdsRPO.get(getUserOrganization().id, page, size, sortModel, filterModel)
}

export function getDataSourceCount(): Promise<number> {
  return sdsRPO.getCount(getUserOrganization().id)
}

export function createDataSource(sds: JDataSourceSubmitValues): Promise<string> {
  return sdsRPO.create(getUserOrganization().id, sds)
}

export function updateDataSource(sds: JDataSourceSubmitValues): Promise<void> {
  return sdsRPO.update(getUserOrganization().id, sds)
}

export function deleteDataSource(sourceId: string): Promise<void> {
  return sdsRPO.delete(getUserOrganization().id, sourceId)
}

export function getUsersDataSourcePermissions(sourceId: string) {
  return sdsRPO.getUsersPermissions(getUserOrganization().id, sourceId)
}

export function getUserDataSourcePermissions(sourceId: string) {
  return sdsRPO.getUserPermissions(getUserOrganization().id, sourceId)
}

export function updateUsersDataSourcePermissions(sourceId: string, acls: any) {
  return sdsRPO.updateUsersPermission(getUserOrganization().id, sourceId, acls)
}

export async function updateDataSourceTags(sds: JDataSource, newTagNames: string[]): Promise<JTag[]> {
  const updatedTags: JTag[] = []

  // If any fresh tag is not found in the data source tags, add it
  for (const newTagName of newTagNames) {
    const foundTag = sds.tags.find(existingTag => newTagName === existingTag.name)
    if (foundTag) {
      updatedTags.push(foundTag)
    } else {
      const newTag = await sdsRPO.addTag(getUserOrganization().id, sds.id, newTagName)
      updatedTags.push(newTag)
    }
  }

  // If any data source tag is not found in the fresh tags, delete it
  for (const existingTag of sds.tags) {
    if (!newTagNames.includes(existingTag.name)) {
      await sdsRPO.deleteTag(getUserOrganization().id, sds.id, existingTag.id)
    }
  }

  return updatedTags
}

export function getDataSourceStatusLevel(status: DATA_SOURCE_STATUSES): STATUS_CHIP_LEVELS {
  switch (status) {
    case DATA_SOURCE_STATUSES.READY:
      return STATUS_CHIP_LEVELS.GREEN

    case DATA_SOURCE_STATUSES.UPDATING:
    case DATA_SOURCE_STATUSES.NOT_READY:
      return STATUS_CHIP_LEVELS.ORANGE

    case DATA_SOURCE_STATUSES.ERROR:
      return STATUS_CHIP_LEVELS.RED

    default:
      return STATUS_CHIP_LEVELS.NEUTRAL
  }
}

export function isHttpsUrl(value: string) {
  return /^https:\/\/.+\..+/.test(value)
}

export function dataSourceTypeCanDisplayDialogSection(section: DETAILS_DIALOG_SECTION, type: DATA_SOURCE_TYPES) {
  return DETAILS_DIALOG_SECTIONS_PERMISSIONS[type].includes(section)
}
