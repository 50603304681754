import { TextField, useTheme } from "@mui/material"
import { translate } from "app/language/service"
import React from "react"

interface QuickFilterInputProps {
  value: string
  onChange: (value: string) => void
}

export const QuickFilterInput = (props: QuickFilterInputProps) => {
  const theme = useTheme()

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const inputElement = document.getElementById("quick-filter") as HTMLInputElement

      if (event.key === "/" && document.activeElement !== inputElement) {
        event.preventDefault()
        inputElement.focus()
      }
    }

    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  return (
    <TextField
      id="quick-filter"
      value={props.value}
      size="small"
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: `${theme.palette.text.primary} `,
            borderRadius: "5px"
          },
          "&.Mui-focused fieldset": {
            borderColor: `${theme.palette.primary.main} `
          }
        }
      }}
      variant="outlined"
      label={translate("label.search")}
      onChange={e => props.onChange(e.target.value)}
    />
  )
}
