import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CircularProgress, Stack, Typography, useTheme } from "@mui/material"
import { translate } from "app/language/service"
import { isJMCAuthenticatedRequest } from "map/utils"
import maplibregl, { Map as MaplibreMap, RequestParameters } from "maplibre-gl"
import { messageSVC } from "message/service"
import React from "react"
import { Layer, Map, Source } from "react-map-gl/maplibre"
import { getRestBaseUrl } from "server/tools/common"
import { DATA_SOURCE_TYPES, JDataSource } from "spatialdatasource/model"
import { sdsRPO } from "spatialdatasource/repository"
import { getAccessToken } from "user/tools/common"

const FEATURES_PAGE_LENGTH = 5000
const MAX_NUM_OF_FEATURES = 10 * FEATURES_PAGE_LENGTH

export interface DataSourceMapProps {
  dataSource: JDataSource
  setNumOfLoadedFeatures?: (n: number) => void
  setIsLoading?: (isLoading: boolean) => void
}

export const DataSourceMap = (props: DataSourceMapProps) => {
  const { dataSource } = props
  const theme = useTheme()

  // necessary for async calls in useEffects
  const mounted = React.useRef(false)
  React.useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const [featureCollection, setFeatureCollection] = React.useState<GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>>({
    type: "FeatureCollection",
    features: []
  })
  const [extent, setExtent] = React.useState<GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>>({
    type: "FeatureCollection",
    features: []
  })
  const [isLoading, setIsLoading] = React.useState(true)
  const [hasLoadingError, setHasLoadingError] = React.useState(false)
  const [map, setMap] = React.useState<MaplibreMap | null>(null)

  const _setIsLoading = (_isLoading: boolean) => {
    setIsLoading(_isLoading)
    props.setIsLoading?.(_isLoading)
  }

  React.useEffect(() => {
    if (map === null || !mounted.current) {
      return
    }
    sdsRPO
      .getDataSourceExtent(dataSource.organizationId, dataSource)
      .then(bounds => {
        // if component has been unmounted, do nothing
        if (!mounted.current) {
          return
        }
        if ([DATA_SOURCE_TYPES.RASTER, DATA_SOURCE_TYPES.WMS_WMTS].includes(dataSource.type)) {
          // no feature to load so nothing else to do
          _setIsLoading(false)
        }
        if ([DATA_SOURCE_TYPES.FILE, DATA_SOURCE_TYPES.WMS_WMTS, DATA_SOURCE_TYPES.VECTOR].includes(dataSource.type)) {
          setExtent({
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Polygon",
                  coordinates: [
                    [
                      [bounds.ne.lng, bounds.ne.lat],
                      [bounds.sw.lng, bounds.ne.lat],
                      [bounds.sw.lng, bounds.sw.lat],
                      [bounds.ne.lng, bounds.sw.lat],
                      [bounds.ne.lng, bounds.ne.lat]
                    ]
                  ]
                },
                properties: {}
              }
            ]
          })
        }
        map.fitBounds(
          [
            { lat: bounds.sw.lat, lng: bounds.sw.lng },
            { lat: bounds.ne.lat, lng: bounds.ne.lng }
          ],
          { animate: true, padding: 10 }
        )
      })
      .catch(reason => {
        console.error(reason)
        setHasLoadingError(true)
        _setIsLoading(false)
      })
  }, [map])

  React.useEffect(() => {
    if ((dataSource.type !== DATA_SOURCE_TYPES.FILE && dataSource.type !== DATA_SOURCE_TYPES.VECTOR) || map === null || !mounted.current) {
      return
    }

    if (featureCollection.features.length >= MAX_NUM_OF_FEATURES) {
      _setIsLoading(false)
      messageSVC.warning(translate("map.too.many.features.warning", { numOfFeatures: MAX_NUM_OF_FEATURES }))
      return
    }

    // fetch a page of features
    sdsRPO
      .getFeatures(dataSource.organizationId, dataSource.id, featureCollection.features.length, FEATURES_PAGE_LENGTH)
      .then(serverFeatureCollection => {
        // if component has been unmounted, do nothing
        if (!mounted.current) {
          return
        }

        if (serverFeatureCollection.features.length === 0) {
          // no or no more features
          if (featureCollection.features.length === 0) {
            messageSVC.warning(translate("map.no.features.warning"), {
              duration: 30000
            })
          }
          _setIsLoading(false)
        }

        const newFeatureCollection = {
          ...featureCollection,
          features: featureCollection.features.concat(serverFeatureCollection.features)
        }

        setFeatureCollection(newFeatureCollection)
        props.setNumOfLoadedFeatures?.(newFeatureCollection.features.length)
      })
      .catch(reason => {
        console.error(reason)
        setHasLoadingError(true)
        _setIsLoading(false)
      })
  }, [map, featureCollection.features.length])

  return (
    <Map
      style={{ width: "100%", height: "100%", backgroundColor: theme.palette.background.default }}
      id="sds-map"
      renderWorldCopies={false}
      mapLib={maplibregl}
      transformRequest={(url, resourceType) => {
        let requestParameters: RequestParameters = {
          url
        }
        if (isJMCAuthenticatedRequest(url)) {
          requestParameters = { ...requestParameters, headers: { Authorization: `Bearer ${getAccessToken()}` } }
        }
        return requestParameters
      }}
      onLoad={e => {
        setMap(e.target)
      }}
    >
      <Source
        id="basemap"
        type="raster"
        tileSize={256}
        maxzoom={19}
        tiles={["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png", "https://b.tile.openstreetmap.org/{z}/{x}/{y}.png", "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png"]}
      >
        <Layer type="raster" id="basemap" />
      </Source>

      {[DATA_SOURCE_TYPES.FILE, DATA_SOURCE_TYPES.WMS_WMTS, DATA_SOURCE_TYPES.VECTOR].includes(dataSource.type) && (
        <Source id="extent" type="geojson" lineMetrics={false} data={extent} maxzoom={23} tolerance={0}>
          <Layer type="line" id="geojson-extent" source={"extent"} paint={{ "line-color": "#555555", "line-width": 3, "line-dasharray": [1, 1] }} minzoom={0} maxzoom={23} />
        </Source>
      )}

      {dataSource.type === DATA_SOURCE_TYPES.FILE || dataSource.type === DATA_SOURCE_TYPES.VECTOR ? (
        <Source id="sds" type="geojson" data={featureCollection} maxzoom={23} tolerance={0}>
          <Layer
            type="fill"
            id="geojson-fill"
            filter={["any", ["==", ["geometry-type"], "Polygon"], ["==", ["geometry-type"], "MultiPolygon"]]}
            source={"sds"}
            paint={{ "fill-color": "#0000ff" }}
            minzoom={0}
            maxzoom={23}
          />
          <Layer
            type="line"
            id="geojson-polygon-border"
            filter={["any", ["==", ["geometry-type"], "Polygon"], ["==", ["geometry-type"], "MultiPolygon"]]}
            source={"sds"}
            paint={{ "line-color": "#ff0000", "line-width": 1 }}
            minzoom={0}
            maxzoom={23}
          />
          <Layer
            type="line"
            id="geojson-line"
            filter={["any", ["==", ["geometry-type"], "LineString"], ["==", ["geometry-type"], "MultiLineString"]]}
            source={"sds"}
            paint={{ "line-color": "#000099", "line-width": 2 }}
            minzoom={0}
            maxzoom={23}
          />
          <Layer
            filter={["any", ["==", ["geometry-type"], "Point"], ["==", ["geometry-type"], "MultiPoint"]]}
            type="circle"
            id="geojson-symbol"
            source={"sds"}
            paint={{
              "circle-color": "#ff0000",
              "circle-radius": 4,
              "circle-stroke-color": "#000000",
              "circle-stroke-width": 1
            }}
            minzoom={0}
            maxzoom={23}
          />
        </Source>
      ) : dataSource.type === DATA_SOURCE_TYPES.RASTER ? (
        <Source
          id="raster"
          type="raster"
          minzoom={0}
          maxzoom={23}
          tileSize={512}
          tiles={[1, 2, 3, 4].map(
            num =>
              `${getRestBaseUrl().replace("api.", `api${num}.`)}/api/mis/wms?organizationId=${
                dataSource.organizationId
              }&request=GetMap&version=1.3.0&CRS=EPSG:3857&width=512&height=512&format=image/png&transparent=true&layers=${dataSource.id}&bbox={bbox-epsg-3857}`
          )}
        >
          <Layer minzoom={0} maxzoom={23} id="raster" type="raster" />
        </Source>
      ) : (
        <></> // WMS/WMST SDS has only an extent layer
      )}

      {isLoading && <CircularProgress sx={{ position: "absolute", top: "50%", left: "50%" }} />}
      {hasLoadingError && (
        <Stack width={"100%"} height={"100%"}>
          <Stack gap={".5rem"} justifyContent={"center"} alignItems={"center"} zIndex={1} width={"100%"} height={"100%"} bgcolor={"#757575bb"}>
            <FontAwesomeIcon size="8x" icon={light("warning")} color={theme.palette.warning.main} />
            <Typography fontWeight={"bold"} color={theme.palette.getContrastText("#757575bb")}>
              {translate("map.loading.error")}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Map>
  )
}
